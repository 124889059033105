<template>
    <Section width="sm">
        <template v-if="!formSuccess">
            <section-header
                :title="slice.primary.title"
                :teaser="slice.primary.teaser"
            />

            <form id="form" class="form-container">
                <div class="form-container__input">
                    <label for="namn">{{ $translate('contact_form_name_label', 'Namn*') }}</label>
                    <input
                        v-model="question.name"
                        type="text"
                        required
                        name="namn"
                    >
                </div>

                <div class="form-container__input">
                    <label for="email">{{ $translate('contact_form_email_label', 'Email*') }}</label>
                    <input
                        v-model="question.email"
                        type="text"
                        required
                        name="email"
                    >
                    <div
                        v-if="submitError"
                        class="form-container__error"
                    >
                        {{ $translate('contact_form_email_error_message', 'Ogiltig email.') }}
                    </div>
                </div>

                <div class="form-container__input">
                    <label for="textarea">{{ slice.primary.textarea }}*</label>
                    <textarea
                        v-model="question.textarea"
                        name="textarea"
                        required
                        rows="8"
                        cols="80"
                    />
                </div>
                <Button
                    background="green"
                    :text="$translate('contact_form_button_text', 'Skicka in')"
                    size="md"
                    :class="{disabled: !enableSubmit}"
                    @click="submit()"
                />
            </form>
        </template>

        <div
            v-else
            class="form-success"
        >
            <div v-html="$prismic.asHtml(slice.primary.contact_form_success_message)" />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    data() {
        return {
            question: {
                name: '',
                email: '',
                textarea: ''
            },
            disableSubmit: false,
            formSuccess: false,
            submitError: false
        };
    },
    computed: {
        enableSubmit() {
            return (
                this.question.name.length &&
                this.question.email.length &&
                this.question.textarea.length
            );
        }
    },
    methods: {
        submit() {
            this.submitError = false;
            this.disableSubmit = true;

            this.$axios({
                method: 'post',
                url: `/f/${process.env.FORMSPREE_ID}`,
                baseURL: 'https://formspree.io',
                data: this.question
            }).then(() => {
                this.$event.questionSubmitted();
                this.formSuccess = true;
            }).catch(() => {
                this.disableSubmit = false;
                this.submitError = true;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
    section::v-deep {

        .form-container {
            margin-top: 40px;

            .btn { max-width: 300px; }
        }

        .form-success {
            @include spacing(padding,8);
            background: $graybg;
        }
    }
</style>
