// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-8a1e7590]{font-family:\"Open Sans\"}.font-header[data-v-8a1e7590]{font-family:\"Hero\"}.banner[data-v-8a1e7590]{background:#f9fafc;margin-top:60px}.banner-content[data-v-8a1e7590]{left:0;margin-top:-70px;display:grid;grid-gap:20px;gap:20px;grid-template-columns:repeat(1,1fr);background:#f9fafc;border-top-right-radius:20px;position:relative;box-sizing:border-box}@media screen and (max-width:700px){.banner-content[data-v-8a1e7590]{padding:20px 20px 0 0;max-width:calc(100% - 40px)}}@media screen and (min-width:700px){.banner-content[data-v-8a1e7590]{margin-top:-140px;padding:30px 30px 0 0;max-width:calc(100% - 100px)}}.banner-content h1[data-v-8a1e7590]{color:#01685e}.banner-content .post-category[data-v-8a1e7590]{font-weight:700}.banner-content__footer[data-v-8a1e7590]{display:grid;grid-gap:10px;gap:10px;grid-template-columns:repeat(1,1fr)}@media screen and (min-width:700px){.banner-content__footer[data-v-8a1e7590]{display:flex;flex-wrap:wrap;justify-content:flex-start;align-items:center}.banner-content__footer .timestamp[data-v-8a1e7590]{padding:0 20px}}.banner-content__footer i[data-v-8a1e7590]{margin-right:5px}.banner-image[data-v-8a1e7590]{border-radius:10px;width:100%;max-height:300px;-o-object-fit:cover;object-fit:cover}@media screen and (min-width:700px){.banner-image[data-v-8a1e7590]{width:calc(100% - 40px);max-height:500px;margin-left:40px}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
