<template>
    <nuxt-link :to="$prismic.linkResolver(question)" class="question-container">
        <p class="question">{{ question.data.question }}</p>
        <div class="author-container">
            <div class="author">
                <span>{{ $translate('answered_by', 'Svarad av') }}</span>
                <prismic-image
                    :img="author.data.image"
                    w="30" 
                    h="30" 
                />
                <span class="author-name">{{ author.data.name }}</span>
            </div>
            <i class="icon fas fa-chevron-right" />
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        question: {
            type: Object,
            required: true
        },
        author: {
            type: Object,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.question-container {
    &:hover {
        color: $green;
        text-decoration: underline;
    }

    .question {
        margin-bottom: 0.5rem;
        font-weight: bold;
        font-style: italic;

    }
    .author-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $muted;
        .author {
            display: flex;
            align-items: center;
            gap: 0.25em;
            @include font-size(14px);
            .author-name {
                font-weight: bold;
                color: $green;
            }
        }
        .icon {
            color: $font;
            @include device(pad) {
                display: none;
            }
        }
    }
}
</style>