// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-132f9ace]{font-family:\"Open Sans\"}.font-header[data-v-132f9ace]{font-family:\"Hero\"}ul[data-v-132f9ace]{border:0;border:1px solid rgba(241,214,245,.7);border-radius:25px;overflow:hidden;box-shadow:0 10px 15px rgba(169,177,200,.25);margin-bottom:30px}ul[data-v-132f9ace],ul li[data-v-132f9ace]{box-sizing:border-box}ul li[data-v-132f9ace]{position:relative}@media screen and (max-width:600px){ul li[data-v-132f9ace]{padding-left:24px}}@media screen and (min-width:600px){ul li[data-v-132f9ace]{padding-left:24px;padding-left:30px}}@media screen and (max-width:600px){ul li[data-v-132f9ace]{padding-right:24px}}@media screen and (min-width:600px){ul li[data-v-132f9ace]{padding-right:24px;padding-right:30px}}@media screen and (max-width:600px){ul li[data-v-132f9ace]{padding-top:16px}}@media screen and (min-width:600px){ul li[data-v-132f9ace]{padding-top:16px;padding-top:20px}}@media screen and (max-width:600px){ul li[data-v-132f9ace]{padding-bottom:16px}}@media screen and (min-width:600px){ul li[data-v-132f9ace]{padding-bottom:16px;padding-bottom:20px}}ul li[data-v-132f9ace]:not(:last-child){border:0;box-sizing:border-box;border-bottom:1px solid rgba(241,214,245,.7)}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
