// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/hero/hero-new.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Hero\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");font-weight:400;font-style:normal;font-display:swap}.font-text[data-v-775fa72c]{font-family:\"Open Sans\"}.font-header[data-v-775fa72c]{font-family:\"Hero\"}.question-container[data-v-775fa72c]:hover{color:#01685e;text-decoration:underline}.question-container .question[data-v-775fa72c]{margin-bottom:.5rem;font-weight:700;font-style:italic}.question-container .author-container[data-v-775fa72c]{display:flex;justify-content:space-between;align-items:center;color:#8c98a4}.question-container .author-container .author[data-v-775fa72c]{display:flex;align-items:center;grid-gap:.25em;gap:.25em;font-size:14px;line-height:20px}.question-container .author-container .author .author-name[data-v-775fa72c]{font-weight:700;color:#01685e}.question-container .author-container .icon[data-v-775fa72c]{color:#333}@media screen and (min-width:600px){.question-container .author-container .icon[data-v-775fa72c]{display:none}}@media screen and (min-width:pad){.question-container .author-container .icon[data-v-775fa72c]{display:none}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
