<template>
    <Section width="md">
        <section-header :title="slice.primary.header" />
        <ul class="grid faq-grid">
            <li v-for="question in questions" :key="question.id">
                <user-question :question="question" :author="getAuthor(question.data.author.id)" />
            </li>
        </ul>
    </Section>
</template>

<script>
import UserQuestion from '~/components/UserQuestion.vue';

export default {
    components: {
        UserQuestion
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            questions: []
        };
    },
    async fetch() {
        const questions = await this.$prismic.api.getByIDs(this.ids);
        this.questions = questions.results;
    },
    computed: {
        ids() {
            return this.slice.items.map(item => item.question.id).filter(id => !!id);
        }
    },
    methods: {
        getAuthor(id) {
            return this.$store.state.authors[id];
        },
    }
};
</script>

<style lang="scss" scoped>
ul {
    @include border;
    @include border-radius(25px);
    overflow: hidden;
    box-shadow: $bxs;
    margin-bottom: 30px;
    li {
        position: relative;
        @include spacing(padding,6,(left,right));
        @include spacing(padding,4,(top,bottom));

        &:not(:last-child) {
            @include border(bottom);
        }
    }
}
</style>