<template>
    <div
        :class="type"
        class="container-box"
    >
        <div class="container-box__inner">
            <prismic-image
                :img="post.data.post_image"
                :w="imageWidth"  
            />
        </div>

        <div class="container-box__inner">
            <div class="inner">
                <span class="font-header">{{ post.data.post_title }}</span>

                <div>
                    <nuxt-link
                        :to="$prismic.linkResolver(post)"
                        class="btn btn-md btn-reset"
                    >   
                        {{ $translate('cta_read_more', 'Läs mer') }}
                        <i class="fa-solid fa-arrow-right" />
                    </nuxt-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true,
            default: undefined
        },
        type: {
            type: String,
            required: false,
            default: 'default'
        }
    },
    computed: {
        post() {
            return this.document;
        },
        imageWidth() {
            const maxWidth = this.type === 'highlighted' 
                ? '650' 
                : '450'; 
            const imageWidth = this.post.data.post_image.dimensions.width;

            return imageWidth > maxWidth ? maxWidth : imageWidth;
        }
    },
};
</script>

<style lang="scss" scoped>
    .container-box {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @media screen and (max-width: 350px) {
                width: 100%;
            }
        }

        .container-box__inner:last-child {
            @include spacing(padding,6);

            .btn { text-align: left; }
        }
    }

    // Layouts
    .container-box.default {
        @include grid(2,0);

        .container-box__inner:last-child {
            @include flex(start);
            @include spacing(padding,6);


            .inner {
                @include grid(1,10px);
            }
        }
    }

    .container-box.highlighted {
        .container-box__inner:last-child {
            @include flex;
            .inner {
                @include grid(1,20px);
            }
        }
    }
</style>
